<template>
    <div>
        <v-card>
            <v-container>
                <v-row>
                    <v-col>
                        <h3 class="ml-3 mt-3"> REPORTES</h3>   
                    </v-col>
                </v-row>
				<hr style="margin: 20px 0px;">
                <v-row>
                    <v-col lg="6" class="pt-0">
                        <v-list>
                            <!--  <v-list-item>
                                <v-list-item-avatar>
                                    <v-btn color="success" fab @click="viewDialogDownload(1)"><i class="fas fa-file-excel" style="font-size:18px"></i></v-btn>
                                    <v-btn x-small @click="viewDialogDownload(1)"> <v-icon>mdi-cloud-download</v-icon></v-btn>
                                </v-list-item-avatar>

                                <v-list-item-content>
                                    <v-list-item-title ><b><a style="color: black"> Ingreso de Materia Prima</a></b></v-list-item-title>
                                </v-list-item-content>
                                
                            </v-list-item> -->

                           <!--  <v-list-item>
                                <v-list-item-avatar>
                                    <v-btn color="success" fab @click="viewDialogEmp()"><i class="fas fa-file-excel" style="font-size:18px"></i></v-btn>
                                </v-list-item-avatar>

                                <v-list-item-content>
                                    <v-list-item-title ><b><a style="color: black"> Ingreso de Materia Prima</a></b></v-list-item-title>
                                </v-list-item-content>
                                
                            </v-list-item> -->

                           <!--  <v-list-item>
                                <v-list-item-avatar>
                                    <v-btn x-small @click="viewDialogDownload(2)"> <v-icon>mdi-cloud-download</v-icon></v-btn>
                                </v-list-item-avatar>

                                <v-list-item-content>
                                    <v-list-item-title ><b><a style="color: black"> Lotes Rechazados</a></b></v-list-item-title>
                                </v-list-item-content>
                                
                            </v-list-item> -->
<!-- 
                            <v-list-item>
                                <v-list-item-avatar>
                                    <v-btn x-small @click="viewDialogDownload(3)"> <v-icon>mdi-cloud-download</v-icon></v-btn>
                                </v-list-item-avatar>

                                <v-list-item-content>
                                    <v-list-item-title ><b><a style="color: black"> AP-FR-012 (Control de indice de madurez de la fruta)</a></b></v-list-item-title>
                                </v-list-item-content>
                                
                            </v-list-item>

                            <v-list-item>
                                <v-list-item-avatar>
                                    <v-btn x-small @click="viewDialogDownload(4)"> <v-icon>mdi-cloud-download</v-icon></v-btn>
                                </v-list-item-avatar>

                                <v-list-item-content>
                                    <v-list-item-title ><b><a style="color: black"> AP-FR-011 (Inspección en recepción sobre la calidad de la materia prima)</a></b></v-list-item-title>
                                </v-list-item-content>
                                
                            </v-list-item> -->

                            <!--  <v-list-item>
                                <v-list-item-avatar>
                                    <v-btn  x-small @click="viewDialogDownload(5)"> <v-icon>mdi-cloud-download</v-icon></v-btn>
                                </v-list-item-avatar>

                                <v-list-item-content>
                                    <v-list-item-title ><b><a style="color: black"> Reporte Flujo de Camiones</a></b></v-list-item-title>
                                </v-list-item-content>
                                
                            </v-list-item> -->

                            <!--  <v-list-item>
                                <v-list-item-avatar>
                                <v-btn
                                    fab @click="viewDialogDownload(3)"
                                    ><v-icon>mdi-cloud-download</v-icon>
                                </v-btn>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                <v-list-item-title>
                                    <b><a @click="viewDialogDownload(3)"
                                        style="color: black">
                                        BD - RECEPTION</a>
                                    </b>
                                </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item> -->
                            <v-list-item>
                                <v-list-item-avatar style="display: flex; align-items: center; justify-content: center">
                                <v-btn
                                    fab @click="viewDialogDownload(1)"
                                    >
                                    <v-icon>mdi-cloud-download</v-icon>
                                </v-btn>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                <v-list-item-title>
                                    <b><a @click="viewDialogDownload(1)"
                                        style="color: black">
                                        AP-FR-012 CONTROL DE INDICE DE MADUREZ DE LA FRUTA</a>
                                    </b>
                                </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <!-- <v-list-item>
                                <v-list-item-avatar>
                                <v-btn
                                    fab @click="viewDialogDownload(2)"
                                    ><v-icon>mdi-cloud-download</v-icon>
                                </v-btn>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                <v-list-item-title>
                                    <b><a @click="viewDialogDownload(2)"
                                        style="color: black">
                                        INGRESO DE MATERIA PRIMA - RECEPCION</a>
                                    </b>
                                </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item> -->

                            <v-list-item>
                                <v-list-item-avatar style="display: flex; align-items: center; justify-content: center">
                                <v-btn
                                    fab @click="viewDialogDownload(4)"
                                    ><v-icon>mdi-cloud-download</v-icon>
                                </v-btn>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                <v-list-item-title>
                                    <b><a @click="viewDialogDownload(4)"
                                        style="color: black">
                                        INSPECCIÓN DE MATERIA PRIMA - RECEPCION</a>
                                    </b>
                                </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                    </v-col>

                </v-row>

            </v-container>
        </v-card>

		<!-- <v-dialog
            v-model="dialogEntryMaterial"
            width="600"
        >
            <v-card>
                <v-container>
                    <v-row>
                        <s-toolbar
                            download
                            @download="PrfEntryMaterialRawReportDownload()"
                            label="Descargar Reporte"
                        >

                        </s-toolbar>
                    </v-row>
                    <v-row>
                        <v-col cols="6">
                            <s-date 
								label="Fecha Inicio"
								v-model="filter.DateBegin"
							></s-date>
                        </v-col>
						<v-col cols="6">
                            <s-date 
								label="Fecha Fin"
								v-model="filter.DateEnd"
							></s-date>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card>
        </v-dialog> -->
<!-- 
       	 <v-dialog
            v-model="dialogLotRejected"
            width="600"
        >
            <v-card>
                <v-container>
                    <v-row>
                        <s-toolbar
                            download
                            @download="PrfLotRejectedByMatureReportDownload()"
                            label="Descargar Reporte"
                        >

                        </s-toolbar>
                    </v-row>
                    <v-row>
                        <v-col cols="6">
                            <s-date 
								label="Fecha Inicio"
								v-model="filterLotRejected.DateBegin"
							></s-date>
                        </v-col>
						<v-col cols="6">
                            <s-date 
								label="Fecha Fin"
								v-model="filterLotRejected.DateEnd"
							></s-date>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card>
        </v-dialog> -->

        
       	<v-dialog
            v-model="dialogDownload"
            v-if="dialogDownload"
            width="600"
        >
            <v-card>
                <v-container>
                    <v-row>
                        <s-toolbar
                            label="Descargar Reporte"
                            excel
                            @excel="ReportDownloadExcel()"
                            pdf
                            @pdf="ReportDownloadPDF">

                        </s-toolbar>
                    </v-row>
                    <v-row>
                        <v-col v-if="xFlag != 1">
                            <s-select-definition
                                v-model="filter.TypeCultive"
                                label="Tipo de Cultivo"
                                :def="1173">
                            </s-select-definition>
                        </v-col>
                        <v-col v-if="xFlag == 1">
                            <s-select-definition
                                v-model="filter.TypeCrop"
                                label="Tipo de Cultivo"
                                :def="1172">
                            </s-select-definition>
                        </v-col>
                        <v-col v-if="xFlag == 1">
                            <s-select-variety
								clearable
								:cultiveID="0"
								label="Variedad"
								full
								v-model="filter.VrtID"
							/>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <s-date 
								label="Fecha Inicio"
								v-model="filter.DateBegin"
							></s-date>
                        </v-col>
						<v-col v-if="xFlag != 1">
                            <s-date 
								label="Fecha Fin"
								v-model="filter.DateEnd"
							></s-date>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card>
        </v-dialog>

        
        
    </div>

</template>

<script>

	import EntryMaterial from "../../../services/FreshProduction/EntryMaterialRawService";
	import LotRejectedByMatureService from "../../../services/FreshProduction/LotRejectedByMatureService";
    import _sReportService from "../../../services/FreshProduction/Report/ReceptionFreshReportService.js";
    /* import sTurnInProcess from "@/components/FreshProduction/Turn/sTurnInProcess.vue" */

    import _sLotProdCalibratedService from "../../../services/FreshProduction/PrfLotProductionCalibratedService";
    import _sQryConfigurationService from "@/services/QueryManager/QryConfigurationService.js";

    import SSelectVariety from "@/components/HarvestSchedule/SSelectVariety";


	export default {
        components: {
            SSelectVariety
        },
		data() {
			return {
				filter: {},
                dialogDownload:false,
                xFlag: 0,
                report: {},
                processing: false
            };
		},

		methods: {

            viewDialogDownload(xFlag)
			{
                this.xFlag = xFlag

				/* this.$fun.alert("Seguro de descargar?", "question")
                .then(resp => {
                    if(resp.value){ */
                        this.dialogDownload = true;
                   /*  }
                }) */
			},

            viewDialogDownloadPDF(xFlag){
                this.xFlag = xFlag

				/* this.$fun.alert("Seguro de descargar?", "question")
                .then(resp => {
                    if(resp.value){ */
                        this.dialogDownload = true;
                   /*  }
                }) */
            },
            ReportDownloadExcel()
                {

                if (this.xFlag == 1) {
                    
                    let parametervalue = [];

                    parametervalue.push(this.filter.DateBegin)
                    parametervalue.push(this.filter.DateEnd)
                    // parametervalue.push(this.filter.TypeCultive)
                    let parametergen = "@DateBegin,@DateEnd";
                    this.downloadReport("BD BRIX - RECEPCION","PRF_RPT_RECEPTION_FRESH_BRIX_A",parametervalue,parametergen);


                }else if(this.xFlag == 2){
                    let parametervalue = [];

                    parametervalue.push(this.filter.DateBegin);
                    parametervalue.push(this.filter.DateEnd);
                
                    let parametergen = " @DateBegin,@DateEnd";
                    this.downloadReport("INGRESO DE MATERIA PRIMA - RECEPCION","REPORT_RECEPTIONFRESH_RAWMATERIAL",parametervalue,parametergen);

                }else if(this.xFlag == 3){
                    let parametervalue = [];

                    parametervalue.push(this.filter.DateBegin);
                    parametervalue.push(this.filter.DateEnd);
                
                    let parametergen = "@DateBegin,@DateEnd";
                    this.downloadReport("BD - RECEPCION","REPORT_RECEPTION_FRES_BD",parametervalue,parametergen);

                }else if (this.xFlag == 4){

                    let parametervalue = [];

                    parametervalue.push(this.filter.DateBegin);
                    parametervalue.push(this.filter.DateEnd);
                
                    let parametergen = "@DateBegin,@DateEnd";
                    this.downloadReport("INSPECCIÓN DE MATERIA PRIMA - INTAKE","PRF_RPT_INTAKE_A",parametervalue,parametergen);
                }
                
            }

            // ReportDownloadExcel(){

            //     if(this.xFlag == 1){ //INGRESO DE MATERIA PRIMA
            //         this.PrfEntryMaterialRawReportDownload()
            //     }

            //     if(this.xFlag == 2){ //LOTES RECHAZADOS
            //         this.PrfLotRejectedByMatureReportDownload()
            //     }

            //     if(this.xFlag == 3){ //REPORTE BRIX
            //         this.reportBRIX()
            //     }

            //     if(this.xFlag == 4){ //REPORT INTAKE
            //         this.reportINTAKE()
            //     }

            //      if(this.xFlag == 5){ //FLUJO DE CAMIONES

            //     }
            // },

            // ReportDownloadPDF(){
            //     if(this.xFlag == 1){ //INGRESO DE MATERIA PRIMA
            //         this.PrfEntryMaterialRawReportDownload()
            //     }

            //     if(this.xFlag == 2){ //LOTES RECHAZADOS
            //         this.PrfLotRejectedByMatureReportDownload()
            //     }

            //     if(this.xFlag == 3){ //REPORTE BRIX
            //         this.reportBrixPDF()
            //     }

            //     if(this.xFlag == 4){ //REPORT INTAKE
            //         this.reportIntakePDF()
            //     }

            //      if(this.xFlag == 5){ //FLUJO DE CAMIONES
                    
            //     }
            // },
,

            ReportDownloadPDF()
            {
                if(this.xFlag == 1){
                    
                    let obj = {};
                    obj.Flag = this.xFlag;
                    obj.NameReport = "BD BRIX - RECEPCION"; 
                    obj.NameProc = "PRF_RPT_RECEPTION_FRESH_BRIX_A_PDF";
                    obj.DateBegin = this.filter.DateBegin;
                    obj.VrtID = this.filter.VrtID;
                    obj.TypeCrop = this.filter.TypeCrop;


                    console.log("x filter", obj);
                    
                    _sLotProdCalibratedService
                        .reportCalibratePDF(obj, this.$fun.getUserID())
                        .then((r) => {

                            this.$fun.downloadFile(
                                r.data,
                                this.$const.TypeFile.PDF,
                                obj.NameReport
                                );
                        
                        });
                }

                if(this.xFlag == 4){
                    
                    let obj = {};
                    obj.Flag = this.xFlag;
                    obj.NameReport = "INSPECCIÓN DE MATERIA PRIMA - INTAKE"; 
                    obj.NameProc = "PRF_RPT_INTAKE_A";
                    obj.DateBegin = this.filter.DateBegin;
                    obj.DateEnd = this.filter.DateEnd;


                    console.log("x filter", obj);
                    
                    _sLotProdCalibratedService
                        .reportCalibratePDF(obj, this.$fun.getUserID())
                        .then((r) => {

                            this.$fun.downloadFile(
                                r.data,
                                this.$const.TypeFile.PDF,
                                obj.NameReport
                                );
                        
                        });
                }

                
            },

			PrfEntryMaterialRawReportDownload()
			{
				EntryMaterial
				.list(this.filter, this.$fun.getUserID())
				.then(resp => {
					if(resp.status == 200){
                        
						EntryMaterial
						.PrfEntryMaterialRawDownload(resp.data, this.$fun.getUserID())
						.then(r => {
							this.$fun.downloadFile(r.data, this.$const.TypeFile.EXCEL, this.$fun.getUserID());
						})
					}
				})
			},

            //***************************************************************************** */
			PrfLotRejectedByMatureReportDownload()
			{
				LotRejectedByMatureService
				.list(this.filter, this.$fun.getUserID())
				.then(resp => {
					if(resp.status == 200){
						LotRejectedByMatureService
						.PrfLotRejectedByMatureExcel(resp.data, this.$fun.getUserID())
						.then(r => {
							this.$fun.downloadFile(r.data, this.$const.TypeFile.EXCEL, this.$fun.getUserID());
						})
					}
				})
			},


            reportBRIX() {
                _sReportService
                    .BRIX(this.filter)
                    .then((r) => {
                        this.$fun.downloadFile(r.data, this.$const.TypeFile.EXCEL, "BRIX");
                    });
                },

            reportBrixPDF() {
                _sReportService.brixRPdf(this.filter, this.$fun.getUserID()).then((r) => {
                    this.$fun.downloadFile(
                    r.data,
                    this.$const.TypeFile.PDF,
                    "Control indicencia madurez de la fruta"
                    );
                });
            },

            reportINTAKE() {
            _sReportService
                .INTAKE(this.filter)
                .then((r) => {
                this.$fun.downloadFile(r.data, this.$const.TypeFile.EXCEL, "INTAKE");
                });
            },

            reportIntakePDF() {
                _sReportService.IntakeRPdf(this.filter, this.$fun.getUserID()).then((r) => {
                    this.$fun.downloadFile(
                    r.data,
                    this.$const.TypeFile.PDF,
                    "Inspección en reception sobre la calidad de la materia prima"
                    );
                });
            },

                downloadReport(QryNameReport,qryname,parametervalue,parametergen) {
				 console.log("QryNameReport",QryNameReport);

				this.report.QryNameReport = QryNameReport	;  //this.DateBegin + "," + this.DateEnd + "," + this.number;
				this.report.QryParamsArray = parametergen	;  //this.DateBegin + "," + this.DateEnd + "," + this.number;
				this.report.QryParamsArrayValue = parametervalue.join(","); //this.DateBegin + "," + this.DateEnd + "," + this.number;
				this.report.QryNameProcedure = qryname;
					//this.report.QryDatabaseNameName + ".." + this.report.QryNameSp;

				this.processing = true;
				_sQryConfigurationService
					.downloadexcel(this.report, this.$fun.getUserID())
					.then(
						(r) => {
							if (r.status == 200) {
								this.processing = false;

								this.$fun.downloadFile(
									r.data,
									this.$const.TypeFile.EXCEL,
									 QryNameReport
								);
							}
						},
						(e) => {
							//this.messageProcessing = e.response.data.Message;
							this.processing = true;
						}
					);

			 
			},    
		},
	};
</script>